<template>
  <ion-page id="page-content">
    <ion-content :fullscreen="true">
      <topbar class="pc-topbar" :class="{ 'letter-ios-14': $store.state.deviceType == 1 }" :bigtitle="true" title="About Us" :showBack="true"></topbar>
      <div class="pc-box big pc-box2">
        <div class="pc-min-content-pct">
          <div class="title-PC2" v-if="$store.state.isPC">About Us</div>
          <div class="content">
            <div v-for="(item, index) in $store.state.moreInfo.aboutUs" :key="index">
              <p class="font-18 font-b main-color m-b-10" v-if="item.type == 1">{{ item.content }}</p>
              <p class="font-16-24 color-66 m-b-30" v-if="item.type == 2">
                {{ item.content }}
              </p>
              <div style="padding: 50% 0 0 0; position: relative" v-if="item.type == 3">
                <iframe
                  :src="item.content"
                  frameborder="0"
                  allow="autoplay; fullscreen; picture-in-picture"
                  allowfullscreen
                  style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
                  title="Thomas Ritchie Story"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";
import topbar from "@/components/topbar.vue";
import config from "../config";
export default {
  name: "aboutUs",
  mixins: [config.globalMixin],
  components: { IonContent, IonPage, topbar },
  data() {
    return {};
  },
  methods: {
    backEvent() {
      this.$router.push({
        path: "/main/more",
      });
    },
  },
  ionViewWillEnter() {
    this.sendGAPageView("About Us");
    this.$store.state.exitApp = false;
  },
};
</script>
